import React from "react"
import { graphql } from "gatsby"
import Seo from "../components/common/seo"
import { Container, Row, Col } from "react-bootstrap"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../components/common/layout"

export const query = graphql`
  query Article($id: ID!) {
    api {
      article(id: $id) {
        id
        slug
        title
        content
        image {
          url
          imageFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
            }
          }
        }
        author {
          title
          description
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  width: 120
                  height: 120
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`

const ArticlePage = ({ data, pageContext, location }) => {
  const article = data.api.article
  return (
    <Layout>
      <Seo title={`${article.title}`} />
      <Container className="single-post padding-bottom">
        {article.image && (
          <Row>
            <Col>
              <GatsbyImage
                image={article.image.imageFile.childImageSharp.gatsbyImageData}
                alt=""
              />
            </Col>
          </Row>
        )}

        <Row>
          <Col md={12} className="pt-4">
            <div className="d-flex">
              <div>
                <h2 className="gradient-text">{article.title}</h2>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-4">
          <Col
            dangerouslySetInnerHTML={{ __html: article.content }}
            className="post-content"
          ></Col>
        </Row>
        {article.author && (
          <Row className="align-items-center mt-4">
            <Col className="d-flex align-items-center flex-row">
              <div>
                <GatsbyImage
                  image={
                    article.author.image.imageFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt=""
                  style={{ borderRadius: "50%" }}
                />
              </div>
              <div className="ml-3">
                <p className="mb-1">
                  <small>Publicado por</small>
                </p>
                <p className="mb-1" style={{ fontSize: "1.15rem" }}>
                  {article.author.title}
                </p>
                <p className="mb-1">
                  <small>{article.author.description}</small>
                </p>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </Layout>
  )
}

export default ArticlePage
